@use "../../../assets/scss/" as *;

.signup_container {
  @include flex(center, center, 30px, column);
  width: 100%;
  padding: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
    @include mobile {
      width: 100%;
      padding: 0;
      background-color: transparent;
    }
  }
  .form_wrapper_up {
    width: 100%;
    max-width: 400px;
  }
  .form_wrapper_in {
    width: 100%;
    min-width: 350px;
    @include mobile { min-width: max-content; }
  }
  .logo {
    width: 200px;
    .img {
      width: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .form {
    @include flex(center, center, 25px, column);
    .inputs {
      @include flex(center, center, 10px, column);
      width: 100%;
      gap: 20px;
      &_row {
        width: 100%;
        display: flex;
        gap: 10px;
      }
    }
  }
}