@use "../colors" as *;
// @use "./typography" as *;

.breadcumb {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: var(--color-gray-400);
    a, span { padding: 3px 5px; }
    a {
        text-decoration: none;
        transition: color 0.3s;
        border-radius: 5px;
        &:hover {
            color: var(--color-primary-500);
            background-color: var(--color-gray-900);
        }
    }
    .divider {
        color: var(--color-gray-400);
    }
}