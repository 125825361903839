@use "/src/assets/scss/" as *;

.container {
    width: 100%;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    border: 1px solid $border;
    border-radius: 8px;
    position: relative;
    &_active {
        background-color: $bg-color;
        position: relative;
        border: none;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 8px;
            padding: 1px;
            background: $gradient;
            -webkit-mask: $gradient-border content-box, $gradient-border;
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            z-index: -1;
        }
    }
    &_box {
        display: flex;
        flex-direction: row;
        gap: 2px;
        position: relative;
        width: 100%;
        &_radio {
            -webkit-appearance: none;
            position: absolute;
            top: 10px;
            right: 10px;
            appearance: none;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            background-color: transparent;
            border: 1px solid $border;
            transition-property: all;
            transition-duration: 350ms;

            &:hover {
                cursor: pointer;
                border-color: $main-color;
            }
            &:checked {
                background-image: $gradient-border;
                border-color: var(--color-primary-500);
                @extend %flex-center;
            }

            &:checked::before {
                content: "✓";
                color: #fff;
                font-size: 18px;
                padding: 5px;
            }
        }
    }
}