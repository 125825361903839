@use "sass:color";

$primary-dark: #121119;
$accent-pink: #e83e8c;
$accent-purple: #8a2be2;
$text-color: #ffffff;
$box-bg: rgba(#1b1a24, 0.9);
$card-radius: 12px;
$transition: all 0.3s ease;

@mixin gradient-text {
  background: linear-gradient(to right, $accent-pink, $accent-purple);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin link-styles {
  color: $accent-pink;
  text-decoration: none;
  transition: $transition;
  font-weight: 500;  
  &:hover {
    color: $accent-purple;
    text-shadow: 0 0 3px rgba($accent-purple, 0.2);
    text-decoration: none;
  }
}

.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(145deg, $primary-dark, color.adjust($primary-dark, $lightness: -5%));
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  color: $text-color;
  padding: 1rem;
  position: relative;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 75% 25%, rgba($accent-purple, 0.05), transparent 60%);
    pointer-events: none;
  }
}

.logo {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 10;
  
  img {
    max-width: 180px;
    height: auto;
  }
}

.maintenance-card {
  background-color: $box-bg;
  backdrop-filter: blur(10px);
  border-radius: $card-radius;
  padding: 3rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
  border: 1px solid rgba($accent-pink, 0.1);
  position: relative;
  margin-top: 60px; 
  
  h1 {
    @include gradient-text;
    font-weight: 600;
    font-size: 1.6rem;
    margin: 1rem 0 1.5rem;
    letter-spacing: -0.5px;
  }
  
  p {
    color: rgba($text-color, 0.85);
    line-height: 1.5;
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
    
    a {
      @include link-styles;
      font-weight: 600;
    }
  }
}

.icon {
  width: 90px;
  height: 90px;
  margin: 1.25rem auto;
  display: block;
  object-fit: contain;
}

.highlight {
  color: $accent-pink;
  font-weight: 500;
}

.divider {
  height: 1px;
  background: linear-gradient(to right, 
    transparent, 
    rgba($accent-pink, 0.3), 
    rgba($accent-purple, 0.3), 
    transparent
  );
  margin: 1.5rem 0;
}

.contact {
  padding: 0.75rem;
  background-color: rgba($primary-dark, 0.4);
  border-radius: $card-radius;
  
  p {
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
  }
  a {
    @include link-styles;
    font-size: 0.9rem;
  }
}