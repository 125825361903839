@use "/src/assets/scss/" as *;

.container {
  &_fields {
    @extend %flex-column;
    background-color: $dark-bg;
    gap: 20px;
    padding: 25px;
    border-radius: 8px;
    @include mobile { padding: 18px; }

    &_input {
      @extend %flex-column;
      gap: 7px;
      width: 100%;
    }

    &_row {
      @extend %flex-justify-between;
      width: 100%;
      gap: 24px;

      @include middleTablet {
        flex-direction: column;
        gap: 20px;
      }
    }

    &_actions {
      @extend %flex-align-end;
      button {
        width: max-content;
        &:hover:not([disabled]) {
          background: linear-gradient($main-color, rgb(0 0 0/70%)) top/100% 900%;
        }
        @include middleTablet {
          width: 100%;
        }
      }
    }
  }
}
