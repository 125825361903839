@use "../../assets/scss/" as *;

.container {
  @include flex(center, center, 30px, column);
  width: 100%;
  padding: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .container_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;

    @include mobile {
      width: 100%;
      padding: 0;
      background-color: transparent;
    }
  }

  .wrapper_up {
    width: 100%;
    max-width: 400px;
  }

  .logo {
    width: 250px;

    .img {
      width: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
