@use "../../../assets/scss/" as *;

.table_wrapper {
  @include tablet {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    td, th {
      color: var(--color-white-500);
      padding: 15px 12px;
      vertical-align: middle;
      font-size: 0.875rem;
      line-height: 1.25rem;
      position: relative;
      // width: 15vw;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      @include mobile { padding: 10px; }
    }
    :global thead.interactive {
      th { padding: 11px 12px; }
    }
    th {
      background-color: $bg-color;
      text-transform: uppercase;
      border-bottom: 2px solid var(--color-paper-500);
    }
      // tbody td {
      //   background-color: var(--color-paper-500);
      //   border-bottom: 1px solid var(--color-paper-400);
      // }
      // tfoot {
      //   td:first-child { border-bottom-left-radius: 8px;}
      //   td:last-child { border-bottom-right-radius: 8px;}
      // }
      th, tfoot td {
        color: var(--color-gray-300);
        font-weight: normal;
        font-size: 12px;
        @include textShadow();
        :global .highlight {
          font-weight: bold;
          color: $text-color-W;
        }
      }
      :global tr.colapse-row {
        cursor: pointer;
        td:last-child::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 8%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }
        &:not(.opened) {
          &:hover td {
            @include bgHover(var(--color-paper-500), 20%, 255, 255, 255)
          }
          td:last-child::after {
            border-top: 7px solid var(--color-gray-400);
          }
        }
        &.opened {
          td:last-child::after {
            border-bottom: 7px solid var(--color-gray-400);
          }
        }
      }
      :global tr.colapse-item {
        > td {
          padding: 0;
        }
      }
      :global tr.colapse-item td, :global tr.colapse-row.opened td {
        background-color: var(--color-paper-600);
        border: none;
      }
      @include mobile {
        :global tr.colapse-row td:last-child {
          padding-right: 35px;
        }
      }
    
    :global .w10 { width: 10% }
    :global .w15 { width: 15% }
    :global .w20 { width: 20% }
    :global .w25 { width: 25% }
    :global .w35 { width: 35% }
    :global .w50 { width: 50% }
  }
}