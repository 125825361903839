@use "/src/assets/scss/" as *;

.container {
  @extend %flex-column;
  gap: 20px;
  width: 100%;

  &_heading {
    @extend %flex-center;
    @extend %flex-justify-between-center;
    color: $text-color-W;
    font-size: 14px;
    @include middle {
      justify-content: center;
    }
  }

  &_history {
    &_table {
      @include middle {
        white-space: nowrap;
      }
    }
  }

  &_content {
    @extend %flex-column;
    gap: 20px;
    @include tablet {
      width: 100%;
    }
    &_upload {
      @extend %flex-column;
      gap: 5px;
      width: 100%;
      gap: 15px;
    }
  }

  &_history {
    padding: 32px 24px;
    background-color: $dark-bg;
    border-radius: 8px;

    &_showMore {
      @extend %flex-start;

      button {
        width: max-content !important;
      }
    }

    table {
      padding: 0 !important;
      tr,
      td,
      th {
        background-color: transparent !important;
      }
    }
  }
}
