@use "../breakpoint" as *;
@use "../colors" as *;

@mixin textShadow() {
    text-shadow: 2px 2px 2px $color-gradient-black-500;
}

a {
    text-decoration: none;
}
.link {
    cursor: pointer;
    color: #5c69f9;
    &:hover{
      text-decoration: underline;
    }
}

.text-gradient-intel {
    font-weight: bold;
    background: #CE4F8C;
    background: linear-gradient(to right, #CE4F8C 0%, #4720E2 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text {
    &-xs {
        font-size: 0.75rem; /* 12px */
        line-height: 1rem; /* 16px */
    }
    &-sm {
        font-size: 0.875rem; /* 14px */
        line-height: 1.25rem;
    }
    &-base {
        font-size: 1rem; /* 16px */
        line-height: 1.5rem; /* 24px */
    }
    &-lg {
        font-size: 1.125rem; /* 18px */
        line-height: 1.75rem; /* 28px */
        @include mobile {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    &-xl {
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        @include mobile {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}