@use "/src/assets/scss/colors" as *;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] { -moz-appearance: textfield; }

.container {
  width: 100%;
  position: relative;
  background-color: var(--color-paper-400);
  border: 1px solid var(--color-gray-700);
  border-radius: 8px;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
  &:focus-within {
    border: 1px solid var(--color-primary-500);
  }
  label + &_input {
    padding-left: 38px;
  }
  &_icon {
    position: absolute;
    margin-left: 10px;
    margin-right: 10px;
    top: 50%;
    color: var(--color-white-500);
    transform: translateY(-50%);
  }
  &_input {
    background-color: transparent;
    border-radius: 8px;
    outline: none;
    border: none;
    width: 100%;
    height: 46px;
    padding: 0 10px;
    font-size: 16px;
    color: var(--color-white-500);
    &::placeholder {
      color: var(--color-gray-500);
    }
    &:disabled {
      background-color: var(--color-paper-700);
      border: 1px solid var(--color-paper-400);
      color: var(--color-gray-400);
      cursor: not-allowed;
    }
    &_error_message {
      color: $error-color;
      font-size: 13px;
      margin-top: 7px;
      display: flex;
    }
  }
  &_reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
    &_icon {
      display: none; 
      margin-right: 10px;
      margin-left: 0;
    }
  }

  // ⭐ Variants
  &_dark {
    background-color: var(--color-gray-900);
    border: 1px solid var(--color-gray-700);
  }
  &_error {
    border: 1px solid $error-color;
  }
  // ⭐ Sizes
  &_small {
    height: 38px;
    input {
      font-size: 14px;
      height: 38px;
    }
  }
  &_tiny {
    height: 32px;
    label + input {
      padding-left: 28px;
    }
    input {
      height: 100%;
      font-size: 12px;
    }
    label {
      margin-left: 7px !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  
  // font-size-adjust: none;
  // -webkit-text-size-adjust: none;
  
  -webkit-text-fill-color: var(--color-white-500) !important;
  
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 30px var(--color-paper-400) inset !important;
}