@use "../../assets/scss/" as *;

.container {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // z-index: -1; /* Place it behind other content */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .box {
    @include flex(center, center, 180px, column);
    padding: 30px;
    // margin-top: 160px;
    max-width: 700px;
    svg {
      width: 100px;
      height: 100px;
    }
    & {
      width: 100%;
      // max-width: 200px;
    }
  }
}

.circle {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  margin-top: -170px;
  display: flex;
  align-items: center;
  justify-content: center;
  &_filled {
    background: $gradient;
    border-radius: 50%;
    color: white;
    width: 144px;
    height: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 60px;
      height: 60px;
      fill: none !important;
        g {
          path {
            stroke: var(--color-white-500);
          }
        }
    }
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 1px;
    background: $gradient;
    -webkit-mask: $gradient-border content-box, $gradient-border;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
