@use "./assets/scss/" as *;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Inter:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    --color-sky-500: #5c69f9;
  }

  body[data-theme='default'] {
    --color-primary-300: #B07CFC;
    --color-primary-400: #9964fd;
    --color-primary-500: #813efb;
    --color-primary-600: #5A4A81;


    --color-paper-300: rgb(75 85 99);
    --color-paper-400: #262836;
    --color-paper-500: #15141E;
    --color-paper-600: #262836;
    --color-paper-700: #15141E;

    --color-greendark-500: #2B3637;
    --color-darkyellow-500: #3B342C;
    --color-darkred-500: #3B2C30;

    --color-green-300: #5EC0A3;

    --color-white-500: #ffffff;

    --color-gray-100: rgb(243 244 246);
    --color-gray-200: rgb(229 231 235);
    --color-gray-300: rgb(209 213 219);
    --color-gray-400: rgb(156 163 175);
    --color-gray-500: rgb(107 114 128);
    --color-gray-600: rgb(75 85 99);
    --color-gray-700: #323441;
    --color-gray-800: #262836; //rgb(31 41 55)
    --color-gray-900: #1e202b; // old: #15141E
  }

  body[data-theme='qualigence'] {
    --color-primary-300: #1a4db3;
    --color-primary-400: #1b9ef7;
    --color-primary-500: #058AE5;
    --color-primary-600: #50a4f9;

    --color-paper-300: rgb(229 231 235);
    --color-paper-400: rgb(243 244 246);
    --color-paper-500: rgb(229 231 235);
    --color-paper-600: #fff;
    --color-paper-700: #e5e7eb;

    --color-greendark-500: #d4faf0;
    --color-darkyellow-500: #fde2c4;
    --color-darkred-500: #f9d5d9;

    --color-green-300: #04bd87;

    --color-white-500: #000000;

    --color-gray-100: #15141E;
    --color-gray-200: rgb(31 41 55);
    --color-gray-300: rgb(55 65 81);
    --color-gray-400: rgb(75 85 99);
    --color-gray-500: rgb(107 114 128);
    --color-gray-600: rgb(156 163 175);
    --color-gray-700: rgb(209 213 219);
    --color-gray-800: rgb(229 231 235);
    --color-gray-900: rgb(229 231 235);
  }
}
.recharts-legend-item {
  margin-left: 36px !important;
  margin-right: 0px !important;
}
.lato {
  font-family: "Lato", "Inter", sans-serif;
  ;
}

.react-datepicker__input-container {
  input {
    height: 48px;
    cursor: pointer;
  }
}

.react-datepicker {
  background-color: var(--color-gray-800) !important;
  color: var(--color-white-500) !important;
  border: 1px solid var(--color-primary-400) !important;
  padding: 8px;
}

.react-datepicker__header {
  border: 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  background-color: var(--color-gray-800) !important;
  color: var(--color-white-500) !important;
}

.react-datepicker__month-text.react-datepicker__month-1.react-datepicker__month-text--selected.react-datepicker__month-text--keyboard-selected.react-datepicker__month-text--today {
  color: var(--color-white-500) !important;
}
.react-datepicker__month-text react-datepicker__month-1 react-datepicker__month-text--selected react-datepicker__month-text--keyboard-selected react-datepicker__month-text--today{
  padding: 4px;

}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--color-primary-400) !important;
}

.react-datepicker__triangle {
  display: none;
}

* {
  outline: none;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;

  // h1, // h2, // h3, // h4, // h5 {
  // font-family: "Poppins", sans-serif;
  // }
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: var(--color-white-500);
  }

  &::-webkit-scrollbar {
    width: 9px;
    height: 10px;
    background-color: gray;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-200);
    border-radius: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-white-500);
  }

  /* Hide arrow buttons */
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Hide corner */
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

body {
  // font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

body,
html {
  background-color: var(--bg-color);
}

label {
  color: var(--color-white-500);
}