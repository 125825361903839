@use "/src/assets/scss/" as *;

.main {
  background-color: $dark-bg;
  padding: 24px;
  border-radius: 8px;

  @include middleTablet {
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
}

.container {
  color: $text-color-W;

  @include middleTablet {
    padding: 10px 15px;
    background-color: $dark-bg;
    border-radius: 8px;
  }

  .elements {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    max-width: 712px !important;

    &_table {
      white-space: nowrap;
      th {
        background-color: transparent;
      }
      &_actions {
        @extend %flex-align-start;
        button {
          width: max-content;
        }
      }
    }

    &_balance {
      @include flex(center, space-between, 5px);
      width: max-content;
      padding: 30px;
      border: 1px solid #babac2bd;
      background-color: #fff;
      color: #000;
      border-radius: 11px;
      height: 40px;

      .item {
        @include flex(flex-end, center, 4px);

        &_icon {
          width: 20px;
          height: 20px;
        }
      }

      &_credits {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}
