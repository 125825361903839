%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-align {
  display: flex;
  align-items: center;
}

%flex-stretch {
  display: flex;
  align-items: stretch;
}

%flex-align-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-column-align {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%flex-justify-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-justify-between {
  display: flex;
  justify-content: space-between;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-column-center-reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

%flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
%flex-align-end {
  display: flex;
  justify-content: flex-end;
}

%flex-start {
  display: flex;
  justify-content: flex-start;
}

%flex-column-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

%flex-align-start {
  display: flex;
  align-items: flex-start;
}

%flex-justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

%flex-justify-evenly-center {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

%flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}