@use "../../assets/scss/" as *;

.main {
  @include flex(center, center, 30px, column);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
    @include mobile {
      width: 100%;
      padding: 0;
      background-color: transparent;
    }
  }

  .form_wrapper_in {
    width: 100%;
    min-width: 350px;
    @include mobile { min-width: max-content; }
  }
  
  &_container {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;

    &_logo {
      width: 70%;
      cursor: pointer;
    }

    &_form {
      @include flex(center, center, 25px, column);
      width: 100%;
    }

    h1 {
      margin: 20px 0;
      @include mobile {
        font-size: 24px;
      }
    }
  }
}
