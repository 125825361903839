@use "/src/assets/scss/" as *;

.container {
  width: 100%;
  padding: 13px;
  @extend %flex-justify-between;
  gap: 12px;
  border: 1px solid $border;
  border-radius: 8px;
  text-align: left;

  &_active {
    background-color: $bg-color;
    position: relative;
    border: none;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      padding: 1px;
      background: $gradient;
      -webkit-mask: $gradient-border content-box, $gradient-border;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      z-index: -1;
    }
  }

  &_box {
    display: flex;
    gap: 12px;
    &_details {
      @extend %flex-column;
      color: var(--color-white-500);
      &_last4 {
        font-size: 14px;
      }
      &_exp {
        font-size: 14px;
        margin-top: 5px;
      }
      &_default {
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;
        cursor: pointer;
        &:hover { text-decoration: underline; }
      }
    }

    &_radio {
      -webkit-appearance: none;
      appearance: none;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      background-color: transparent;
      border: 1px solid var(--color-gray-700);
      transition-property: all;
      transition-duration: 350ms;
      &:hover {
        cursor: pointer;
        border-color: $main-color;
      }
      &:checked {
        background-image: $gradient-border;
        position: relative;
        @extend %flex-center;
      }

      &:checked::before {
        content: "✓";
        color: #fff;
        font-size: 18px;
        padding: 5px;
      }
    }
  }
}
