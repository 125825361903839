@use "../colors" as *;
@use "../breakpoint" as *;
@use "./global" as *;
@use "../flex" as *;
@use "./typography" as *;

.interface {
  width: 100%;
  border-radius: 10px;
  border-left: 2px solid var(--color-gray-900); 
  border-right: 2px solid var(--color-gray-900);
  background-color: var(--color-paper-500);
  -webkit-box-shadow: 0px 2px 11px 0px rgba(255,255,255,0.1);
  -moz-box-shadow: 0px 2px 11px 0px rgba(255,255,255,0.1);
  box-shadow: 0px 2px 11px 0px rgba(255,255,255,0.1);
  @extend %flex-column;
  &_heading {
    gap: 15px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @extend .text-sm;
    align-items: center;
    padding: 15px 10px;
    border-radius: 8px 8px 0 0;
    @include mobile {
      padding: 12px 10px;
      align-items: flex-start;
      > div {
        width: 100%;
      }
    }
    &_row {
      @extend %flex-align;
      justify-content: space-between;
      width: 100%;
      gap: 15px;
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      } 
    }
  }
  &_footer {
    gap: 15px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @extend .text-sm;
    align-items: center;
    padding: 15px 10px;
    border-radius: 0 0 8px 8px;
    @include mobile {
      padding: 12px 10px;
      align-items: flex-start;
      > div {
        width: 100%;
      }
    }
  }
  &_foot {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    @include mobile {
      flex-direction: column;
      align-items: flex-start
    }
  }
  &_noResults {
    @extend %flex-column-align;
    gap: 15px;
    padding: 70px 0;
  }

}