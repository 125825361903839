@use "/src/assets/scss/" as *;

.container {
  min-height: 100vh;
  padding: 25px 0 100px;
  gap: 40px;
  color: var(--color-white-500);
  background-color: $bg-color;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @extend %flex-column-align;
  &_box {
    position: relative;
    @extend %flex-column;
    gap: 25px;
    text-align: left;
    @include mobile {
      padding: 0 20px;
    }
    p {
      @include mobile {
        font-size: 0.91rem;
        line-height: 1.42rem;
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 30px;
      letter-spacing: 1px;
      @include mobile {
        font-size: 27px;
      }
    }
    h4 {
      letter-spacing: 1px;
    }
  }
}
