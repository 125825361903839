@use "/src/assets/scss/" as *;

.container {
  @extend %flex-column;
  gap: 13px;
  &_box {
    @extend %flex-align;
    @extend .text-sm;
    gap: 7px;

    &_svg {
      @extend %flex-align;
      cursor: pointer;
      padding: 4px;
      background-color: rgba(0,0,0,0.3);
      border-radius: 100%;
      color: white;
      transition: all 0.5s;
      &:hover {
        background-color: rgba(0,0,0,5)
      }
    }
  }
  &_dropDownItems {
    @extend %flex-column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 13px;
  }
}
.likely {
  color: #c58865;
}
.verified {
  color: #23a681;
}