@use "/src/assets/scss/" as *;
.box {
  width: 100%;
  @extend %flex-column;
  gap: 20px;

  // Heading Part
  &_heading {
    @extend %flex-justify-between-center;

    @include middleTablet {
      width: 100%;
      justify-content: flex-start;
    }

    &_input {
      width: 216px !important;
      border-radius: 8px;
      input {
        padding: 5px 0 !important;
        color: $text-color-W;

        &::placeholder {
          color: $text-color-W;
        }
      }
      @include middleTablet {
        display: none;
      }
    }

    &_text {
      color: white;
      font-size: 24px;
      font-weight: 500;
    }
  }

  // Box Content
  &_content {
    @extend %flex-column;

    // Tabs part
    &_tabs {
      display: flex;
      align-items: center;
      //Each tab
      &_tab {
        padding: 8px 12px;
        border-radius: 8px 8px 0px 0px;
        color: var(--color-white-500);
        cursor: pointer;
        margin-right: 8px;

        &_active {
          background-color: $dark-bg;
          color: $text-color-W;

          @include middleTablet {
            border-radius: 28px;
          }
        }

        @include middleTablet {
          margin-bottom: 16px;
        }
      }

      @include middleTablet {
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    //Box Forms
    &_forms {
      background-color: $dark-bg;
      padding: 32px 24px;
      border-radius: 8px;
      @include mobile {
        padding: 14px;
      }
      &_firstActive {
        border-radius: 0px 8px 8px 8px !important;

        @include middleTablet {
          border-radius: 8px !important;
        }
      }
    }
  }
}

.form {
  &_oneinput {
    display: flex;
    align-items: center;
    gap: 15px;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    
    @include mobile {
      gap: 15px;
      min-width: 100%;
      flex-direction: column;
      align-items: flex-start
    }
  }

  &_table {
    @include middle {
      overflow-x: scroll !important;
    }
  }
}