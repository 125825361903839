@use "/src/assets/scss" as *;

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner_container {
  width: 100%;
  height: 100vh;
  @include flex(center, center);
}
.loading_spinner {
  animation: spinner 1.5s linear infinite;
}
