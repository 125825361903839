@use "/src/assets/scss/" as *;

.fileUpload {
    border: 2px dashed var(--color-gray-400);
    border-radius: 8px;
    padding: 40px 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
    gap: 25px;
    @extend %flex-column-align;
    @extend .text-sm;
   
    &.dragOver {
      background-color: #f0f0f0;
    }
  
    input[type='file'] {
      display: none;
    }

    &_text {
        @extend %flex-center;
        gap:12px;
        font-size: 14px;
        @include middle {
          p {
            display: none;
          }
        }

        &_mobile {
          display: none!important;
          color: $main-color;
          @include middle {
            display: block!important;
          }
        }
    }
  }