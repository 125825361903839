@use "/src/assets/scss/breakpoint" as *;
@use "/src/assets/scss/theme/typography" as *;
.button {
	background-color: var(--color-primary-500);
	outline: none;
	border: none;
	border-radius: 11px;
	cursor: pointer;
	text-transform: capitalize;
	display: flex;
	align-items: center;
	justify-content: center;
	// @include textShadow();
	gap: 6px;
	color: #fff;
	font-weight: 400;
	transition: all .3s ease-in-out;

	// SIZES
	&_xxs {
		@extend .text-xs;
		padding: 3px 7px;
	}
	&_xs {
		@extend .text-xs;
		height: 32px;
		padding: 6px 8px;
	}
	&_sm {
		@extend .text-sm;
		height: 38px;
		padding: 9px 11px;
		@include mobile {
			padding: 7px 9px;
		}
	}
	&_md {
		font-size: 15px; padding: 10px 15px;
	}
	&_lg {
		font-size: 16px; padding: 12px 17px;
	}

	// STYLES
	&:hover:not([disabled]):not(&_download):not(&_processing) {
		background-color: var(--color-primary-400);
		// background: linear-gradient(var(--color-primary-500), rgb(0 0 0/75%)) top/100% 500%;
	}
	&_secondary {
		transition: none;
		background-color: var(--color-sky-500);
		&:hover, &:active, &:focus {
			background: linear-gradient(var(--color-sky-500), rgb(0 0 0/90%)) top/100% 500% !important;
		}
	}
	&_inactive {
		color: var(--color-gray-100);
		background-color: var(--color-paper-400) !important;
		border: 1px solid var(--color-gray-700);
		text-shadow: none;
	}
	&_white {
		text-shadow: none;
		background-color: #fff;
		color: var(--color-primary-500);
	}
	&_download {
		background-color: transparent;
		padding: 0;
		color: var(--color-primary-500);
		&:hover {
			text-decoration: underline;
			background-color: transparent !important;
		}
	}
	&_processing {
		cursor: default;
		pointer-events: none;
	}

	// EVENTS
	&:disabled{
		background-color: #6d696946;
		cursor: default;
		border: none;
	}
	&_outline {
		background-color: transparent;
		color: var(--color-white-500);
		border: 1px solid var(--color-primary-500);
		&:hover {
			color: white
		}
	}
}
