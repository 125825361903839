@use "../../assets/scss/" as *;

@keyframes glitch-anim-1 {
  0% { clip-path: inset(40% 0 61% 0); transform: translate(-5px, 0) scale(1.02); }
  20% { clip-path: inset(92% 0 1% 0); transform: translate(5px, -3px) scale(1.01); }
  40% { clip-path: inset(43% 0 1% 0); transform: translate(-7px, 2px) scale(1.03); }
  60% { clip-path: inset(25% 0 58% 0); transform: translate(8px, -1px) scale(1.02); }
  80% { clip-path: inset(54% 0 7% 0); transform: translate(-10px, 3px) scale(1.01); }
  100% { clip-path: inset(58% 0 43% 0); transform: translate(0, 0) scale(1); }
}

@keyframes glitch-anim-2 {
  0% { clip-path: inset(25% 0 58% 0); transform: translate(-5px, 0) rotate(0.5deg); }
  20% { clip-path: inset(1% 0 94% 0); transform: translate(5px, -2px) rotate(-0.5deg); }
  40% { clip-path: inset(58% 0 43% 0); transform: translate(-7px, 1px) rotate(0.3deg); }
  60% { clip-path: inset(3% 0 83% 0); transform: translate(8px, -3px) rotate(-0.7deg); }
  80% { clip-path: inset(76% 0 21% 0); transform: translate(-10px, 2px) rotate(0.4deg); }
  100% { clip-path: inset(19% 0 6% 0); transform: translate(0, 0) rotate(0deg); }
}

@keyframes text-glitch {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 1px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(1px, 1px); }
  80% { transform: translate(1px, -1px); }
  100% { transform: translate(0); }
}

@keyframes flicker {
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% { opacity: 0.99; }
  20%, 21.999%, 63%, 63.999%, 65%, 69.999% { opacity: 0.4; }
}

@keyframes distort {
  0% { transform: skew(0deg); }
  25% { transform: skew(0.5deg, 0.5deg); }
  50% { transform: skew(-0.5deg, -0.1deg); }
  75% { transform: skew(0.3deg, -0.5deg); }
  100% { transform: skew(-0.2deg, 0.2deg); }
}

@keyframes glitch-block {
  0% { left: -100%; opacity: 0; }
  10% { left: 0%; opacity: 0.8; }
  15% { left: 100%; opacity: 0; }
  100% { left: 100%; opacity: 0; }
}

@keyframes noise-anim {
  0% { clip: rect(18px, 9999px, 46px, 0); }
  5% { clip: rect(92px, 9999px, 61px, 0); }
  10% { clip: rect(100px, 9999px, 98px, 0); }
  15% { clip: rect(137px, 9999px, 147px, 0); }
  20% { clip: rect(30px, 9999px, 55px, 0); }
  25% { clip: rect(56px, 9999px, 38px, 0); }
  30% { clip: rect(86px, 9999px, 37px, 0); }
  35% { clip: rect(32px, 9999px, 12px, 0); }
  40% { clip: rect(59px, 9999px, 55px, 0); }
  45% { clip: rect(68px, 9999px, 25px, 0); }
  50% { clip: rect(82px, 9999px, 33px, 0); }
  55% { clip: rect(49px, 9999px, 44px, 0); }
  60% { clip: rect(72px, 9999px, 67px, 0); }
  65% { clip: rect(99px, 9999px, 91px, 0); }
  70% { clip: rect(44px, 9999px, 48px, 0); }
  75% { clip: rect(33px, 9999px, 15px, 0); }
  80% { clip: rect(93px, 9999px, 64px, 0); }
  85% { clip: rect(20px, 9999px, 71px, 0); }
  90% { clip: rect(23px, 9999px, 22px, 0); }
  95% { clip: rect(79px, 9999px, 73px, 0); }
  100% { clip: rect(10px, 9999px, 18px, 0); }
}

.container {
  @include flex(center, center, 30px, column);
  width: 100%;
  height: 100vh;
  color: $text-color-W;
  background: linear-gradient(135deg, #1a1d2a 0%, #0d0f18 100%);
  position: relative;
  overflow: hidden;
  animation: flicker 8s infinite;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
      radial-gradient(circle at 25% 25%, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0) 3%),
      radial-gradient(circle at 75% 30%, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0) 4%),
      radial-gradient(circle at 46% 66%, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0) 2.5%);
    box-shadow: inset 0 0 100px rgba(0,0,0,0.5);
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
      linear-gradient(90deg, transparent 0%, transparent 94%, rgba(255,255,255,0.15) 95%, transparent 96%),
      linear-gradient(135deg, transparent 44%, rgba(255,255,255,0.15) 45%, transparent 46%),
      linear-gradient(45deg, transparent 44%, rgba(255,255,255,0.15) 45%, transparent 46%),
      linear-gradient(to right, transparent 10%, rgba(0,0,0,0.2) 11%, transparent 12%),
      linear-gradient(to right, transparent 20%, rgba(0,0,0,0.2) 21%, transparent 22%),
      linear-gradient(to bottom, transparent 40%, rgba(0,0,0,0.2) 41%, transparent 42%);
    background-size: 300% 300%, 200% 200%, 300% 300%, 100% 100%, 100% 100%, 100% 100%;
    pointer-events: none;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      linear-gradient(0deg, rgba(0,0,0,0.2) 50%, transparent 50%),
      linear-gradient(90deg, rgba(255,255,255,0.04), rgba(255,255,255,0.04) 2px, transparent 3px, transparent 8px),
      linear-gradient(transparent 0%, rgba(0,0,0,0.1) 10%, rgba(0,0,0,0.1) 90%, transparent 100%);
    background-size: 100% 2px, 10px 100%, 100% 100%;
    pointer-events: none;
    z-index: 2;
    opacity: 0.7;
    animation: distort 1s infinite alternate;
  }

  .vignette {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 65%, rgba(0,0,0,0.7) 100%);
    pointer-events: none;
    z-index: 3;
  }

  .glitch-block {
    position: absolute;
    width: 100%;
    height: 3px;
    background: rgba(255,255,255,0.2);
    z-index: 4;
    opacity: 0;
    &:nth-child(1) { top: 30%; animation: glitch-block 2.5s infinite 0.5s; }
    &:nth-child(2) { top: 70%; animation: glitch-block 2.5s infinite 1s; }
  }

  h1 {
    font-size: 126px;
    margin: 0;
    font-weight: 800;
    position: relative;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: -2px;
    z-index: 10;
    text-shadow: 2px 2px 0px #6366f1;

    &::before,
    &::after {
      content: "404";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &::before {
      color: #d946ef;
      animation: glitch-anim-1 1.5s infinite linear alternate-reverse;
    }

    &::after {
      color: #6366f1;
      animation: glitch-anim-2 2s infinite linear alternate-reverse;
    }
  }

  p {
    font-size: 24px;
    margin: 15px 0 0;
    font-weight: 500;
    position: relative;
    z-index: 10;
    letter-spacing: 0.5px;
    text-shadow: 0 0 2px rgba(255,255,255,0.5);
    color: #d946ef;
    animation: text-glitch 1.5s infinite;

    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: -2px;
      color: #6366f1;
      background: rgba(13, 15, 24, 0.7);
      overflow: hidden;
      clip: rect(0, 900px, 0, 0);
      animation: noise-anim 3s infinite linear alternate-reverse;
    }
  }

  button {
    width: max-content;
    padding: 12px 28px;
    background: linear-gradient(90deg, #6366f1, #8b5cf6);
    border: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 20px rgba(99, 102, 241, 0.3);
    position: relative;
    z-index: 10;
    margin-top: 20px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 25px rgba(99, 102, 241, 0.4);
      background: linear-gradient(90deg, #8b5cf6, #d946ef);
    }
  }
}