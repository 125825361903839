@use "/src/assets/scss/" as *;

.main {
  background-color: $dark-bg;
  padding: 24px;
  border-radius: 8px;

  @include middleTablet {
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
}

.container {
  // @extend %flex-column;

  &_fields {
    @extend %flex-column;
    background-color: $dark-bg;
    gap: 20px;
    padding: 25px;
    border-radius: 8px;
    

    &_actions {
      @extend %flex-align-start;
      gap: 20px;
      margin-top: 0.75rem;

      button {
        width: max-content;

        &:hover:not([disabled]) {
          background: linear-gradient($main-color, rgb(0 0 0/70%)) top/100% 900%;
        }

        @include middleTablet {
          width: 100%;
        }
      }
    }

    @include middleTablet {
      gap: 20px;
    }
  }

  
}
