@use "/src/assets/scss/" as *;
.main {
  @extend %flex-column-align;
  gap: 20px;
  &_container {
    width: 100%;
    position: relative;
    border: 2px dashed var(--color-gray-400);
    border-radius: 8px;
    padding: 40px 20px;
    text-align: center;

    &_file {
      @extend %flex-center;
      flex-direction: column;
      width: 100%;
      gap: 16px;

      &_box {
        @extend %flex-align;
        gap: 10px;
      }

      &_name {
        font-size: 18px;
        font-weight: 400;
        color: $text-color-W;
      }

      &_emails {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-white-500);
        white-space: nowrap;
      }
      &_icon {
        color: $text-color-W;
      }
    }

    &_close {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 15px;
      padding-top: 15px;
      line-height: 1;
      cursor: pointer;
      opacity: .5;
      &:hover {
        opacity: 1;
      }
    }
  }

  button {
    width: max-content;
  }

  &_loadingProcess {
    @extend %flex-justify-between-center;
    width: 100%;
    background-color: $bg-color;
    border: 1px solid $border;
    padding: 12px;
    border-radius: 8px;

    &_text {
      @extend %flex-column;
      gap: 4px;

      &_title {
        color: $text-color-W;
      }

      &_sub {
        font-size: 14px;
        color: var(--color-white-500);
        font-weight: 400;
      }
    }
  }
}
