@use "../colors" as *;
@use "./typography" as *;

.badge {
    width: max-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 13px;
    color: white;
    gap: 5px;
    padding: 6px 9px;

    // SIZES
    &.xs {
        padding: 3px 5px;
        font-size: 12px;
    }
    &.sm {
        padding: 4px 7px;
    }

    // COLORS
    &.primary { background: var(--color-primary-500); }
    &.primary, &.pm { background: var(--color-primary-500); }
    &.sd { background-color: var(--color-sky-500); }
    &.Likely {
        background: #3B342C;
        color: #E6A457;
    }
    &.Unvalidated {
        background: #513e24;
        color: #ffab57;
    }
    &.Verified, &.green {
        background-color: var(--color-greendark-500);
        color: var(--color-green-300);
    }
    &.off {
        color: var(--color-white-500);
        background-color: var(--color-gray-700);
    }
}
.badgeTable {
    width: max-content;
    border-radius: 8px;
    font-size: 13px;
    color: white;
    gap: 5px;
    padding: 6px 9px;

    // SIZES
    &.xs {
        padding: 3px 5px;
        font-size: 12px;
    }
    &.sm {
        padding: 4px 7px;
    }

    // COLORS
    &.primary, &.pm { background: var(--color-primary-500); }
    &.sd { background-color: var(--color-sky-500); }
    &.Likely {
        background: #3B342C;
        color: #E6A457;
    }
    &.Verified, &.green {
        background-color: var(--color-greendark-500);
        color: var(--color-green-300);
    }
    &.off {
        color: var(--color-white-500);
        background-color: var(--color-gray-700);
    }
}