.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  &.style-1 {
    td, th {
      padding: 12px 1rem;
      font-size: 0.875rem;
    }
    th, td.label { color: var(--color-gray-400) }
    th {
      font-weight: normal;
      border-style: solid;
      border-color: var(--color-paper-400);
      border-top-width: 1px;
      border-bottom-width: 1px;
    }
    tr {
      &.noBorder td { border-bottom: none; }
      td {
        color: var(--color-white-500);
        border-bottom: 1px solid var(--color-paper-400);
      }
    }
  }
}