@use "/src/assets/scss/" as *;

.container {
  // @extend %flex-column;

  &_fields {
    @extend %flex-column;
    background-color: $dark-bg;
    gap: 20px;
    padding: 25px;
    border-radius: 8px;

    &_actions {
      @extend %flex-align-end;

      button {
        width: max-content;

        &:hover:not([disabled]) {
          background: linear-gradient($main-color, rgb(0 0 0/70%)) top/100% 900%;
        }

        @include middleTablet {
          width: 100%;
        }
      }
    }

    @include middleTablet {
      gap: 20px;
    }
  }

  
}

.editor {
  font-family: monospace;
  color: white;
  padding: 10px;
  font-size: 12px;
  background-color: rgb(38, 40, 54);
  border-radius: 8px;
}