@use "../../assets/scss/" as *;

.main {
  @include flex(center, center, 30px, column);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  &_container {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;

    &_logo {
      width: 70%;
      cursor: pointer;
    }

    &_form {
      @include flex(center, center, 25px, column);
      width: 100%;
    }
  }
  
  .container_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
    @include mobile {
      width: 100%;
      padding: 0;
      background-color: transparent;
    }
  }

  .icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary-500);
    width: 112px;
    height: 112px;
    border-radius: 100%;
    color: var(--color-white-500);
    margin-bottom: 4px;
  }
  
  .page_title {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
    @include mobile {
      font-size: 24px;
    }
  }
  
  .description {
    font-size: 14px;
    color: var(--color-gray-300);
    margin-bottom: 20px;
  }
  
  .back_link {
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .loading_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
  }
  
  .error_container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .error_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 112px;
    border-radius: 100%;
    margin-bottom: 4px;
  }
  .error_title {
    font-size: 24px;
    font-weight: bold;
    color: var(--color-primary-500); 
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); 
    letter-spacing: 0.5px; 
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 1px;
      background-color: var(--color-primary-500);
      border-radius: 2px;
    }
  }
  .error_message {
    font-size: 14px;
    color: var(--color-gray-300);
    margin-bottom: 10px;
  }
}