@use "/src/assets/scss/" as *;

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  z-index: 100;
  @extend %flex-center;

  @include mobile {
    padding: 0 20px;
  }

  &_body {
    position: relative;
    height: max-content;
    max-height: 750px!important;
    text-align: center;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    gap: 20px;
    border-radius: 15px;
    border-color: black;
    background: var(--color-paper-700);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: var(--color-white-500);
    animation-name: animatetop;
    animation-duration: 1s;

    @include mobile {
      padding: 10px;
    }

    &_close {
      position: absolute;
      right: 0;
      top: 0;
      padding-top: inherit;
      padding-right: inherit;
      color: var(--color-white-500);
      &_btn {
        @extend %flex-center;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        opacity: .5;
        &:hover { opacity: 1; }
        @include mobile {
          width: 30px;
          height: 30px;
        }
      }
    }
    &::-webkit-scrollbar { width: 0; }
  }
}

.closeAnimation {
  animation-name: animateClose;
  animation-duration: 1s;
}

@-webkit-keyframes animatetop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes animateClose {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes animateClose {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
