@use "../../../assets/scss/breakpoint" as *;
@use "../../../assets/scss/theme/global" as *;
@use "../../../assets/scss/theme/typography" as *;

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 100;
  &_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;
    width: 100%;
    @media (max-width: 1024px) {
      gap: 20px;
    }
  }
  &_logo { width: 200px;
    @media (min-width:811px) and (max-width:1022px) {
      display: none
    }
  }
  &_menu {
    display: flex;
    flex: 1;
    gap: 18px;
    @include tablet { display: none; }
    :global .active {
      color: var(--color-primary-500);
      background-color: var(--color-gray-900);
    }
    a {
      @extend .text-sm;
      text-transform: capitalize;
      color: var(--color-white-500);
      padding: 9px 10px;
      border-radius: 8px;
      transition: all 0.2s ease 0s;
      border-bottom: 3px transparent solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: clip;
      &:hover {
        color: var(--color-primary-500);
        background-color: var(--color-gray-900);
      }
    }
    @media (max-width: 1024px) {
      gap: 5px;
    }
  }

  &_nav {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    color: var(--color-white-500);
    
    &_burger {
      display: none;
    }

    button {
      width: max-content;
      height: max-content;
    }

    .dropdown {
      position: relative;
      background-color: rgba(245, 245, 245, 0.39);
      padding: 9px;
      border-radius: 11px;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      svg {
        width: 15px;
        height: 15px;
        path {
          fill: #fff;
        }
      }
      &:hover {
        transition: all 0.5s;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:hover .dropdown_item { display: block; }
      &_item {
        display: none;
        position: absolute;
        top: 36px;
        left: 0;
        background-color: rgba(185, 185, 185, 0.39);
        padding: 10px;
        width: 100%;
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        a { color: #fff; }
      }
    }

    @include mobile {
      display: none;
      &_burger {
        display: block;
      }
    }
    @include middle {
      display: none;
      &_burger {
        display: block;
      }
    }
    @include tablet {
      display: none;
      &_burger {
        display: block;
      }
    }
  }
}