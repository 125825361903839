@use "/src/assets/scss/" as *;

.main {
    @include mobile {
        @include container();
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &_heading {
        @extend %flex-column-center;
        gap: 8px;

        &_desc {
            width: 100%;
            max-width: max-content !important;
            color: var(--color-white-500);
            text-align: center;
            font-size: 14px;
        }
    }

    &_cost {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 20px;

        // padding: 20px 20px 0 20px;
        // border-bottom: 1px solid $border;
        @include mobile {
            background-color: var(--color-primary-500);
            padding: 15px 0;
            border-radius: 10px;
        }
    }

    &_payment {
        width: 100% !important;
        text-align: start;
    }
}