@mixin mobile {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin mobileH {
  @media only screen and (max-height: 710px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 810px) {
    @content;
  }
}

@mixin middle {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin middleTablet {
  @media only screen and (max-width: 780px) {
    @content;
  }
}
