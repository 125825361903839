@use "/src/assets/scss/breakpoint" as *;
@use "/src/assets/scss/theme/typography" as *;
.table_key {
    // text-transform: uppercase;
    vertical-align: top;
    @include textShadow();
    @include mobile {
        text-transform: none;
    }
}

.container {
    display: flex;
    background-color: var(--color-paper-600);
    padding-bottom: 15px;
    @include mobile {
        flex-direction: column;
    }
    td {
        padding: 13px 20px !important;
        @include mobile {
            padding: 6px 10px !important;
        }
    }
}