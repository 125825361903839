//Global colors
$main-color: var(--main-color);
$bg-color: var(--bg-color);
$dark-bg: var(--dark-bg);
$body-bg: var(--body-bg);
$error-color: var(--error-color);

$color-gradient-black-500: var(--color-gradient-black-500);
$color-gradient-black-200: var(--color-gradient-black-200);

//Gradient
$gradient: var(--gradient);
$gradient-border: var(--gradient-border);

//text-colors
$text-color-W: var(--text-color-W);

//border
$border: var(--border);

//scrollbar
$scrollbar-background: var(--scrollbar-background);
$scrollbar-hover-color: var(--scrollbar-hover-color);