@use "../../../assets/scss/" as *;

.popup {
    background-color: $body-bg;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
    p {
        margin-bottom: 15px;
        max-width: none!important;
    }

    button {
        margin-top: 15px;
    }
}