@use "/src/assets/scss/" as *;

.main {
  @extend %flex-column-center;
  @include container('sm');
  &_transactions {
    @extend %flex-column;
    gap: 20px;

    // &_heading {
    //   @extend %flex-justify-between-center;
      
    //   @include middleTablet {
    //     flex-direction: column;
    //     align-items: flex-start;
    //     // padding: 0 16px;
    //   }
    // }

    // @include middleTablet {
    //   width: 100%;
    // }
  }

}
