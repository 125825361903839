@use "../colors" as *;
@use "../breakpoint" as *;

@mixin container($width: 'base', $padding-top: 40) {
    padding-top: $padding-top+px;
    padding-bottom: 80px;
    margin: auto;
    gap: 40px;

    @if $width == 'base' {
        // width: $container-width;
    }
    @else if $width == 'sm' {
        width: 712px;
    }
    @include tablet {
        gap: 25px;
        width: 100%;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 60px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
@mixin bgHover($bg: 'black', $opacity: 90%, $r: 0, $g: 0, $b: 0) {
    cursor: pointer;
    background: linear-gradient($bg, rgb($r $g $b / #{$opacity})) top/100% 500%;
}
@mixin boxShadow() {
    -webkit-box-shadow: 4px 6px 5px 0px $color-gradient-black-200;
    -moz-box-shadow: 4px 6px 5px 0px $color-gradient-black-200;
    box-shadow: 4px 6px 5px 0px $color-gradient-black-200;
}