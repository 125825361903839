@use "../colors" as *;
@use "../flex" as *;
@use "../breakpoint" as *;
@use "./global" as *;
@use "./typography" as *;

.pagination {
    display: inline-flex;
    height: 38px;
    background-color: var(--color-paper-400);
    border: 1px solid var(--color-gray-700);
    border-radius: 8px;
    width: max-content;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    // @include boxShadow();
    button {
        @extend %flex-center;        
        @include textShadow();
        padding: 0 10px;
        height: 100%;
        font-size: 15px;
        display: flex;
        color: var(--color-white-500);
        border-right: 1px solid var(--color-gray-700);
        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        @include mobile {
            padding: 0 13px;
            font-size: 14px;
        }
        &.disabled, &.active, &.info {
            cursor: pointer;
            pointer-events: none;
        }
        &.disabled {
            color: var(--color-gray-700);
        }
    }
    div {
        @extend %flex-center;        
        @include textShadow();
        padding: 0 10px;
        height: 100%;
        font-size: 15px;
        display: flex;
        color: var(--color-white-500);
        border-right: 1px solid var(--color-gray-700);
        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        &:hover, &.active {
            @include bgHover(var(--color-paper-400));
        }
        @include mobile {
            padding: 0 13px;
            font-size: 14px;
        }
        &.disabled, &.active, &.info {
            cursor: pointer;
            pointer-events: none;
        }
        &.disabled {
            color: var(--color-gray-700);
        }
    }
    div:last-child, div:first-child {
        padding: 0 10px;
    }
    div:last-child {
        border-right: none;
    }
    button:last-child, button:first-child {
        padding: 0 10px;
    }
    button:last-child {
        border-right: none;
    }
}