@use "/src/assets/scss/" as *;

.dropdown {
  @include textShadow();
  @extend %flex-center;
  color: var(--color-gray-300);
  gap: 10px;
  padding: 0 15px;
  height: 37px;
  border-radius: 8px;
  background-color: var(--color-paper-400);
  border: 1px solid var(--color-gray-700);
  @include boxShadow();
  &:hover {
    @include bgHover(var(--color-paper-400));
  }
}