@use "../../../assets/scss/theme/typography" as *;

.SearchMainBody {
    .borderBottom {
        border-bottom: 1px solid var(--color-paper-600);
    }
    table {
        width: 100%;
        table-layout: fixed;
        &.main {
            color: var(--color-white-500); 
            > tbody {
                vertical-align: top;
                font-size: 14px;
                .is_active + .is_active > td {
                    border-top: 2px solid var(--color-paper-500) !important;
                }
                tr {
                    vertical-align: middle;
                    &:not(.noHover):hover > td {
                        cursor: pointer;
                        background-color: var(--color-paper-600);
                        transition: background-color 0.3s;
                    }
                    &:not(:last-child) > td {
                        border-bottom: 1px solid var(--color-paper-600);
                    }
                   
                    &.is_active > td {
                        background-color: var(--color-paper-600);
                    }
                    &.is_extendable {
                        &.is_active td:last-child:after { transform: rotate(180deg); }
                        // ⭐ Arrow
                        td:last-child {
                            position: relative;
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 27px;
                                right: 17px;
                                width: 0;
                                height: 0;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid var(--color-gray-500);
                                transition: transform 0.3s, border-top-color 0.3s;
                            }
                        }
                    }
                }
            }
        }
    }
    thead th { 
        padding: .3rem 0.55rem; 
        border-bottom: 1px solid var(--color-paper-600); 
    }
    tfoot td { padding: 0.75rem; border-top: 1px solid var(--color-paper-600); }
    thead th, tfoot td {
        font-weight: normal;
        font-size: 13px;
        color: var(--color-gray-400);
        @include textShadow();
    }
}
.SearchMainFilter {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 35px;
    .is_open + .is_open {
        border-top: 1px solid var(--color-paper-500);
    }
    li {
        color: var(--color-white-500);
        font-size: 15px;
        position: relative;
        border-bottom: 1px solid var(--color-paper-600);
        transition: background-color 0.3s;
        .content-title {
            display: flex;
            padding: 16px 25px;
            cursor: pointer;
        }
        &.is_active {
            color: var(--color-white-500);
            .content-title {
                &:before {
                    cursor: pointer;
                    content: '';
                    display: block;
                    position: absolute;
                    top: 23px;
                    left: 10px;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: var(--color-primary-500);
                }
            }
        }
        &.is_open {
            color: var(--color-primary-500);
            border-bottom-color: var(--color-paper-400);
            background-color: var(--color-paper-400);
            &:after {
                transform: rotate(180deg);
                border-top-color: var(--color-primary-500);
            }
        }
        &:hover {
            background-color: var(--color-paper-400);
        }
    }
    .range-picker-custom {
        width: 100%;
        background-color: var(--color-paper-400);
        color: var(--color-white-500);
        border: 1px solid var(--color-gray-700);
    }
    .ant-picker-separator, .ant-picker-suffix, .ant-picker-clear{
        color: var(--color-white-500) !important; 
    }
    .ant-picker input::placeholder{
        color: var(--color-gray-300) !important; 
        opacity: 1; 
    }
}