@use "/src/assets/scss/" as *;

.main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  &_container {
    // padding: 24px;
    // background-color: var(--color-paper-500);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    @include mobile { padding: 25px 17px; }
    &_allCards {
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &_heightLimited {
        max-height: 340px;
        overflow: auto;
      }
      &_actions {
        display: flex;
        align-items: flex-start;
        button {
          font-size: 15px; width: max-content;
        }
      }
    }
    &_card {
      position: relative;
      // padding: 20px 0;
      gap: 16px;
      display: flex;
      flex-direction: column;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   inset: 0;
      //   border-radius: 8px;
      //   padding: 1px;
      //   background: $gradient;
      //   -webkit-mask: $gradient-border content-box, $gradient-border;
      //   -webkit-mask-composite: xor;
      //   mask-composite: exclude;
      // }
      &_inputBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
        label { color: var(--color-white-500); }
        fieldset {
          background-color: var(--color-paper-400);
          border: 1px solid  var(--color-gray-700);
          margin-top: 7px;
          border-radius: 8px;
          padding: 10px 14px;
        }
      }
      &_row {
        display: flex;
        justify-content: space-between;
        gap: 16px;
      }
      &_save {
        @extend %flex-align;
        gap: 8px;
        cursor: pointer;
        z-index: 10;
        width: max-content;
        input[type="checkbox"] {
          -webkit-appearance: none;
          appearance: none;
          width: 16px;
          height: 16px;
          background-color: var(--color-gray-800);
          border: 1px solid var(--color-gray-700);
          border-radius: 3px;
        }

        input:checked {
          background-color: $main-color;
          position: relative;
          @extend %flex-center;
        }

        input:checked::before {
          content: "✓";
          color: #fff;
          padding: 5px;
        }

        span {
          font-size: 12px;
          color: var(--color-white-500);
        }
      }

      &_actions {
        button {
          width: max-content;
          &:hover:not([disabled]) {
            background: linear-gradient($main-color, rgb(0 0 0/70%)) top/100% 900%;
          }
        }
      }
    }
  }
}
