@use "../../../assets/scss/" as *;

.burger {
  &_menu {
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: flex-start;
    &_items {
      width: 100%;
      height: 100vh;
      padding: 25px 16px;
      background-color: var(--bg-color);
      border-right: 1px solid var(--color-gray-700);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      img { width: 170px; }
    }
    &_closeBtn {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      padding-right: 20px;
      width: 40%;
      height: 100%;
    }
    &.open {
      transform: translateX(0); transition: transform 0.4s ease-in-out;
    }
    &.close {
      transform: translateX(-100%); transition: transform 0.4s ease-in-out;
    }
  }
}